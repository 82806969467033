
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

@Component({})
export default class PasswordReset extends Vue {
  newPassword = '';
  newPasswordRepeat = '';
  email = '';
  valid = false;
  state = '';

  get rulesPassword() {
    return [
      (v: string) => !!v || 'Passwort benötigt',
      (v: string) => v.length >= 8 || 'Passwort muss mindestens 8 Zeichen lang sein',
      (v: string) =>
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) || 'Passwort muss mindestens ein Sonderzeichen enthalten',
      (v: string) => /\d/.test(v) || 'Passwort muss mindestens eine Zahl enthalten'
    ];
  }

  get rulesPasswordRepeat() {
    return [
      (v: string) => !!v || 'Passwort wiederholen',
      (v: string) => v === this.newPassword || 'Passwörter stimmen nicht überein'
    ];
  }

  get rulesEmail() {
    return [(v: string) => !!v || 'E-Mail benötigt', (v: string) => /.+@.+\..+/.test(v) || 'E-Mail ungültig'];
  }

  validateForm() {
    this.valid = (this.$refs.form as any).validate();
  }

  // get route params
  get token() {
    return this.$route.query.token;
  }

  resetPassword() {
    axios
      .post(`${BACKEND_URL}/reset-password`, { password: this.newPassword, token: this.token })
      .then(() => {
        this.state = 'reset';
      })
      .catch((error: any) => {});
  }

  requestPasswordReset() {
    axios
      .post(`${BACKEND_URL}/request-password-reset`, { email: this.email })
      .then(() => {})
      .catch((error: any) => {});
    this.state = 'requested';
  }
}
