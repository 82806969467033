<template>
  <v-data-table :items="getItems" sort-by="name">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Parametrisierung Stundensätze</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="setItemsFromOriginals">Reset</v-btn>
        <v-btn @click="save" color="green" :disabled="!anyChanged">Save</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:header>
      <tr align="center">
        <th>Materialgruppe \ Dicke</th>
        <th v-for="(header, ind) in headers" :key="`${header}_${ind}`">
          {{ header }}
        </th>
      </tr>
    </template>
    <template v-slot:item="{ item }">
      <tr align="left">
        <th>{{ item.header }}</th>
        <td v-for="(header, ind) in headers" :key="`${item.header}_${header}_${ind}`" :style="getStyle(item, header)">
          <v-text-field
            type="number"
            :value="item[header]"
            @blur="updateItem(item, header, $event.target.value)"
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      Keine Daten
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'Params',
  data: () => ({
    items_originals: [],
    items: [],
    headers: []
  }),

  computed: {
    getItems() {
      return this.items;
    },
    anyChanged() {
      return this.items.some(function(val) {
        for (let i of Object.keys(val)) {
          if (i.endsWith('_changed')) {
            if (val[i]) {
              return true;
            }
          }
        }
        return false;
      });
    }
  },

  methods: {
    getStyle(item, header) {
      if (item[`${header}_changed`]) {
        return 'background: green';
      }
      return '';
    },
    updateItem(item, header, value) {
      if (item[header] !== Number(value)) {
        item[`${header}_changed`] = true;
        item[header] = Number(value);
      }
    },
    receiveOriginals() {
      axios
        .post(`${BACKEND_URL}/params_hourly`, {
          table: 'ZALastechHourlyCost',
          x: 'thickness',
          y: 'material_class',
          edit: 'hourly_cost'
        })
        .then(response => {
          if (response.status === 200) {
            this.items_originals = response.data.items;
            this.headers = response.data.headers;
            this.setItemsFromOriginals();
          }
        })
        .catch(error => {});
    },
    setItemsFromOriginals() {
      this.items = [];
      for (let e of this.items_originals) {
        let e_ext = Object.assign({}, e);
        for (let i of Object.keys(e)) {
          e_ext[`${i}_changed`] = false;
        }
        this.items.push(e_ext);
      }
    },
    save() {
      let updateVals = [];

      for (let i = 0; i < this.items.length; i++) {
        for (let k of Object.keys(this.items_originals[i])) {
          let item = this.items[i];
          if (item[`${k}_changed`]) {
            updateVals.push({ y: item.header, x: k, edit: item[k] });
          }
        }
      }

      // TODO SEND TO SERVER AND REFRESH VALUES
      axios
        .post(`${BACKEND_URL}/params_update`, {
          table: 'ZALastechHourlyCost',
          x: 'thickness',
          y: 'material_class',
          edit: 'hourly_cost',
          edits: updateVals
        })
        .then(response => {
          if (response.status === 200) {
            this.receiveOriginals();
          }
        })
        .catch(error => {});
    }
  },
  created() {
    this.receiveOriginals();
  }
};
</script>

<style scoped></style>
