
import { Vue } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';

export default Vue.extend({
  data: () => ({
    dialog: false,
    activationDialog: false,
    activateAutoUser: false,
    activateAutoUserUsername: '',
    autoUserCustomerNumber: '',
    search: '',
    headers: [
      { text: 'Benutzername', value: 'username' },
      { text: 'Status', value: 'status' },
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      { text: 'Firma', value: 'company' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Strasse', value: 'street' },
      { text: 'PLZ Stadt', value: 'plz_city' },
      { text: 'Telefon', value: 'phone' },
      { text: 'Kundennr.', value: 'customer_id' },
      { text: 'Notizen', value: 'notes' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    users: [],
    activationItem: {
      activation: false,
      username: '',
      notifyUser: false
    },
    editedIndex: -1,
    editedItem: {
      name: '',
      company: '',
      username: '',
      email: '',
      street: '',
      plz_city: '',
      phone: '',
      customer_id: '',
      notes: '',
      password: ''
    },
    defaultItem: {
      name: '',
      company: '',
      username: '',
      email: '',
      street: '',
      plz_city: '',
      phone: '',
      customer_id: '',
      notes: '',
      password: ''
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Benutzer' : 'Benutzer bearbeiten';
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.retrieveUsers();
  },

  methods: {
    retrieveUsers() {
      axios
        .get(`${BACKEND_URL}/users`)
        .then(response => {
          if (response.status === 200) {
            this.users = response.data.users;
          }
        })
        .catch(error => {});
    },

    editItem(item: any) {
      this.editedIndex = (this.users as any).indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = '';
      this.dialog = true;
    },

    showActivationDialog(item: any) {
      this.activationItem.username = item.username;
      this.activationItem.activation = !item.activated;
      this.activationDialog = true;
    },

    activateAutoUserDialog(item: any) {
      this.activateAutoUserUsername = item.username;
      this.activateAutoUser = true;
    },

    deleteItem(item: any) {
      if (confirm('Soll dieser Benutzer wirklich gelöscht werden?')) {
        axios.post(`${BACKEND_URL}/users/delete`, item).then(() => this.retrieveUsers());
      }
    },

    closeActivationDialog() {
      this.activationDialog = false;
      this.activationItem = {
        activation: false,
        username: '',
        notifyUser: false
      };
    },

    closeAutoUserDialog() {
      this.activateAutoUser = false;
      this.autoUserCustomerNumber = '';
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    activateAutoUserCall() {
      axios
        .post(`${BACKEND_URL}/users/activate_new`, {
          username: this.activateAutoUserUsername,
          customerNumber: this.autoUserCustomerNumber
        })
        .then(() => this.retrieveUsers());
      this.activateAutoUser = false;
    },
    activateUser() {
      axios
        .post(`${BACKEND_URL}/users/activate`, {
          username: this.activationItem.username,
          notification: this.activationItem.notifyUser
        })
        .then(() => this.retrieveUsers());
      this.activationDialog = false;
    },

    deactivateUser() {
      axios
        .post(`${BACKEND_URL}/users/deactivate`, {
          username: this.activationItem.username
        })
        .then(() => this.retrieveUsers());
      this.activationDialog = false;
    },

    save() {
      // edited
      if (this.editedIndex > -1) {
        axios.post(`${BACKEND_URL}/users/update`, this.editedItem).then(() => this.retrieveUsers());
      }
      // new user
      else {
        axios.post(`${BACKEND_URL}/users/create`, this.editedItem).then(() => this.retrieveUsers());
      }
      this.close();
      this.retrieveUsers();
    }
  }
});
