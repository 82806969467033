import { render, staticRenderFns } from "./OfferDesk.vue?vue&type=template&id=24547b78&scoped=true"
import script from "./OfferDesk.vue?vue&type=script&lang=js"
export * from "./OfferDesk.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24547b78",
  null
  
)

export default component.exports