
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import jwtDecode from 'jwt-decode';

@Component({})
export default class LogReg extends Vue {
  loginPassword = null;
  loginUsername = null;
  loginError: any = '';

  twoFactorInputRequired = false;
  twoFactorCodeResendDisabled = false;
  twoFactorError: any = '';
  twoFactorEmailResponse = null;
  twoFactorExpired = false;
  authCode = null;

  registerEmail = null;
  registerCompany = null;
  registerStreet = null;
  registerPlz = null;
  registerCity = null;
  registerPhone = null;
  registerForename = null;
  registerSurname = null;
  registerError: any = '';
  registerState = 'CHECK_EMAIL';

  valid = false;
  currentTab = 1;

  rules = {
    required: (value: any) => !!value || 'Pflichtfeld',
    counter: (value: any) => value.length <= 40 || 'Max 40 characters',
    email: (value: any) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Ungültige E-Mail Adresse';
    }
  };

  get registrationRules() {
    return [(v: string) => !!v || 'Pflichtfeld', (v: string) => v.length <= 40 || 'Max 40 characters'];
  }

  resetRegister() {
    this.registerState = 'CHECK_EMAIL';
    this.registerEmail = null;
    this.registerError = null;
  }

  resendTwoFactorCode() {
    // disable for 60 seconds
    this.twoFactorCodeResendDisabled = true;
    setTimeout(() => {
      this.twoFactorCodeResendDisabled = false;
    }, 60000);

    axios
      .post(`${BACKEND_URL}/resend-2fa`, {
        username: this.loginUsername
      })
      .then(response => {
        if (response.status === 200) {
        }
      });
  }

  checkEmail() {
    axios
      .post(`${BACKEND_URL}/check_email`, {
        email: this.registerEmail
      })
      .then(response => {
        if (response.status === 200) {
          this.registerState = response.data.result;
        }
      })
      .catch(_ => {
        this.loginError = 'Email bereits registriert!';
      });
  }

  switchToLogin() {
    this.resetRegister();
    this.currentTab = 1;
  }

  register() {
    axios
      .post(`${BACKEND_URL}/register`, {
        email: this.verification_email,
        company: this.registerCompany,
        street: this.registerStreet,
        plz: this.registerPlz,
        city: this.registerCity,
        forename: this.registerForename,
        surname: this.registerSurname,
        phone: this.registerPhone
      })
      .then(response => {
        if (response.status === 200) {
          this.registerState = 'SENT_REGISTER_REQUEST';
        }
      })
      .catch(_ => {
        this.registerError = 'Registrierung fehlgeschlagen!';
      });
  }

  login() {
    this.loginError = null;
    this.twoFactorError = null;
    this.twoFactorExpired = false;
    this.twoFactorEmailResponse = null;
    axios
      .post(`${BACKEND_URL}/login`, {
        username: this.loginUsername,
        password: this.loginPassword,
        auth_code: this.authCode
      })
      .then(response => {
        if (response.status === 200) {
          const user = jwtDecode(this.$cookies.get('jwt_header_payload'));
          this.$store.commit('setUser', user);
          // this.$store.dispatch('loadPendingOffer');
          this.$router.push('/');
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          if (error.response.data.status === '2FA_REQUIRED') {
            this.twoFactorInputRequired = true;
            this.twoFactorEmailResponse = error.response.data.email;
          } else if (error.response.data.status === '2FA_FAILED') {
            this.twoFactorError = '2FA fehlgeschlagen! Code überprüfen.';
          } else if (error.response.data.status === '2FA_EXPIRED') {
            this.twoFactorInputRequired = true;
            this.twoFactorError = 'Ihr 2FA Code ist abgelaufen!';
            this.twoFactorExpired = true;
            this.twoFactorEmailResponse = error.response.data.email;
          }
          this.loginError = 'Login fehlgeschlagen! Bitte Username und Passwort überprüfen.';
        }
      });
  }

  validateForm() {
    this.valid = (this.$refs.form as any).validate();
  }

  get registerStepperStep() {
    return this.registerState === 'CHECK_EMAIL' ? 1 : 2;
  }

  get validRegisterEmail() {
    return this.rules.email(this.registerEmail) === true;
  }

  get verification_email() {
    return this.$route.query.email;
  }

  get verification_token() {
    return this.$route.query.token;
  }

  mounted() {
    if (this.verification_email && this.verification_token) {
      this.currentTab = 2;
      axios
        .post(`${BACKEND_URL}/verify_email`, {
          email: this.verification_email,
          token: this.verification_token
        })
        .then(response => {
          this.registerState = response.data.result;
        })
        .catch(_ => {
          this.registerState = 'REGISTRATION_EMAIL_VERIFICATION_FAILED';
        });
    }
  }
}
