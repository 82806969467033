<template>
  <v-dialog v-if="!email" v-model="dialog" width="500" persistent>
    <template v-slot:activator>
      <upload-card
        :acceptedFileTypes="acceptedFiles"
        @files-added-event="onFilesAdded"
        :allowMultiple="false"
        :click-handler="openDialog"
        mainText="Neues internes Angebot erstellen"
        subText="Drag and Drop E-Mail (.msg) oder Klick für leeres Angebot"
      >
      </upload-card>
    </template>
    <v-card>
      <v-card-title>
        <div>
          <div class="text-h5">
            Internes Angebot ohne E-Mail erstellen
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-text-field label="Offertenname / Betreff" v-model="offerName" />
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">Abbrechen</v-btn>
        <v-btn @click="addNewCalculation">Erstellen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-container v-else>
    <v-row>
      <v-col cols="6">
        <div class="text-h6">Offerte {{ $store.getters.currentOfferID | formatOfferId }} : {{ email.emailSubject }}</div>
      </v-col>
      <v-col cols="3">
        <v-btn @click="newMail">Neue E-Mail</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn @click="overview">Zu Übersicht</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn @click="setStatus('In Bearbeitung')" :color="getStatusColor('In Bearbeitung')">In Bearbeitung</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn @click="setStatus('Angebot versendet')" :color="getStatusColor('Angebot versendet')"
          >Angebot versendet
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-col>
          <mail-text-viewer
            :sender="email.emailSender"
            :body="email.emailBody"
            :recipients="email.emailRecipients"
            :subject="email.emailSubject"
          ></mail-text-viewer>
        </v-col>
      </v-col>
      <v-col cols="8" class="d-flex fill-height">
        <v-row no-gutters class="fill-height">
          <v-col cols="4">
            <v-col>
              <v-card class="mx-auto" tile>
                <v-subheader>KALKULATIONEN</v-subheader>
                <v-card-actions>
                  <v-btn small class="green" @click="downloadExcel()">
                    Excel Generieren
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-progress-circular v-if="loading" :rotate="-90" :size="25" :width="5" color="primary" indeterminate>
                  </v-progress-circular>
                  <v-icon v-if="success">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <mail-customer-search
                :sender="email.emailSender"
                @selectedCustomer="onSelectedCustomerChange"
              ></mail-customer-search>
            </v-col>
          </v-col>
          <v-col cols="8">
            <v-col>
              <mail-attachment-viewer
                :attachments="email.emailAttachments"
                :emailId="email.emailId"
              ></mail-attachment-viewer>
            </v-col>
            <v-col style="height: 900px">
              <v-card tile height="100%">
                <v-subheader>ANHANG-VORSCHAU</v-subheader>
                <v-card-text style="height: 90%">
                  <object ref="iframe" height="100%" width="100%" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadCard from '@/components/util/UploadCard';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import MailTextViewer from '@/components/MailTextViewer';
import MailAttachmentViewer from '@/components/MailAttachmentViewer';
import MailCustomerSearch from '@/components/MailCustomerSearch';
import { formatOfferId } from '@/store';

export default {
  name: 'OfferDesk',
  components: { MailCustomerSearch, MailAttachmentViewer, MailTextViewer, UploadCard },
  data() {
    return {
      acceptedFiles: ['.msg'],
      selectedCustomer: null,
      selectedItem: 0,
      loading: false,
      success: false,
      dialog: false,
      offerName: ''
    };
  },
  watch: {
    email(val) {
      if (val) {
        this.$store.commit('setExcelUUID', this.email.excels[this.selectedItem].uuid);
        this.$store.commit('setExcelVersion', this.email.excels[this.selectedItem].version);
      }
    }
  },
  computed: {
    email() {
      return this.$store.getters.email;
    }
  },
  methods: {
    onSelectedCustomerChange(val) {
      this.selectedCustomer = val;
      this.$store.commit('setExcelPayload', this.selectedCustomer);
      axios.post(`${BACKEND_URL}/set_email_contact`, {
        offer_id: this.$store.getters.currentOfferID,
        contact: this.selectedCustomer
      });
    },
    changeSelection() {
      this.$store.commit('setExcelUUID', this.email.excels[this.selectedItem].uuid);
      this.$store.commit('setExcelVersion', this.email.excels[this.selectedItem].version);
    },
    onFilesAdded(files) {
      for (const file of files) {
        this.uploadFile({ file: file, progress: 0 });
      }
    },
    openDialog() {
      this.dialog = true;
    },
    addNewCalculation() {
      const formData = new FormData();
      formData.set('offer_id', this.$store.getters.currentOfferID);
      formData.set('charge_size', this.$store.getters.chargeSize);
      formData.set('offer_name', this.offerName);
      axios.post(`${BACKEND_URL}/upload_mail`, formData).then(value => {
        this.dialog = false;
        this.offerName = '';
        this.$store.commit('setOfferID', value.data.offer_id);
        this.$store.commit('setEmail', {
          emailSender: value.data.mail.sender,
          emailRecipients: value.data.mail.recipients,
          emailBody: value.data.mail.body,
          emailSubject: value.data.mail.subject,
          emailAttachments: value.data.mail.attachments,
          emailId: value.data.mail.mail_id,
          excels: value.data.mail.excels
        });
        this.$store.commit('setEmailContact', value.data.contact);
      });
    },
    copySelectedCalculation() {
      axios
        .post(`${BACKEND_URL}/copy_calculation`, {
          offer_id: this.$store.getters.currentOfferID,
          excel_uuid: this.$store.getters.excelUUID
        })
        .then(value => {
          this.$store.commit('setEmail', {
            emailSender: value.data.sender,
            emailRecipients: value.data.recipients,
            emailBody: value.data.body,
            emailSubject: value.data.subject,
            emailAttachments: value.data.attachments,
            emailId: value.data.mail_id,
            excels: value.data.excels
          });
        })
        .catch(error => {});
    },
    onUploadProgress(wrappedFile, progressEvent) {
      wrappedFile.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    },
    formatDate(date) {
      const options = {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric'
      };
      return new Date(date).toLocaleDateString('de-CH', options);
    },
    setStatus(status) {
      axios
        .post(`${BACKEND_URL}/set_status`, {
          offer_id: this.$store.getters.currentOfferID,
          status: status
        })
        .then(res => {
          this.$store.commit('setOfferStatus', status);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadExcel() {
      this.success = false;
      this.loading = true;
      let excelPayload = this.$store.getters.excelPayload;
      const currentOfferID = this.$store.getters.currentOfferID;
      axios
        .post(
          `${BACKEND_URL}/spreadsheet`,
          {
            excelPayload: excelPayload,
            offerId: this.$store.getters.currentOfferID
          },
          { responseType: 'blob' }
        )
        .then(res => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${formatOfferId(currentOfferID)}.xlsm`;
          link.click();
          this.success = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusColor(text) {
      if (text === this.$store.getters.getCurrentOfferStatus) {
        return 'primary';
      } else {
        return 'grey';
      }
    },
    newMail() {
      this.success = false;
      this.$store.commit('resetOffer');
      this.$store.commit('setEmail', null);
    },
    overview() {
      this.success = false;
      this.$store.commit('resetOffer');
      this.$store.commit('setEmail', null);
      this.$router.push('/');
    },
    uploadFile(wrappedFile) {
      const formData = new FormData();
      formData.set('file', wrappedFile.file);
      formData.set('offer_id', this.$store.getters.currentOfferID);
      formData.set('charge_size', this.$store.getters.chargeSize);
      axios
        .post(`${BACKEND_URL}/upload_mail`, formData, {
          onUploadProgress: event => this.onUploadProgress(wrappedFile, event)
        })
        .then(value => {
          this.$store.commit('setOfferID', value.data.offer_id);
          this.$store.commit('setEmail', {
            emailSender: value.data.mail.sender,
            emailRecipients: value.data.mail.recipients,
            emailBody: value.data.mail.body,
            emailSubject: value.data.mail.subject,
            emailAttachments: value.data.mail.attachments,
            emailId: value.data.mail.mail_id,
            excels: value.data.mail.excels
          });
          this.$store.commit('setEmailContact', value.data.contact);
        });
    }
  }
};
</script>

<style scoped></style>
